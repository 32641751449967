import React, { Component } from "react";
import "./SingleWarranty.css";
import "../../common.css";
import config from "../../../config";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import {
  IoIosBrush as CreatedIcon,
  IoIosThumbsUp as ApprovedIcon,
  IoIosRocket as InTransitIcon,
  IoIosTimer as ReviewIcon,
  IoIosThunderstorm as ServiceIcon,
  IoMdCloud as CompleteIcon,
  IoMdHome as ReceivedIcon,
  IoIosThumbsDown as DeclinedIcon,
} from "react-icons/io";
import HalfPageImageRight from "../../../pageContainers/halfPageImageRight/halfPageImageRight";
import moment from "moment";
import utilityFunctions from "../../../store/utilityFunctions";
import backgroundImg from "../../../img/robot-background-3.jpg";
Modal.setAppElement("body");

export default class SingleWarranty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      details: null,
      id: null,
    };
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.id;
    this.setState({ id });
    this.retrieveWarranty(id);
  }
  retrieveWarranty = (id) => {
    this.setState({ loading: true });
    this.props.protectedFetch(config.backendServer + "getuserprofile", null, errorFunctions.checkResponseThenJson).then((res) => {
      if (!res.firstName) {
        // User profile doesn't exist, put warning on page
        this.setState({ noProfile: true });
      }
      this.props
        .protectedFetch(config.backendServer + "getsinglewarranty", { id }, errorFunctions.checkResponseThenJson)
        .then((res) => {
          this.setState({ loading: false, details: res });
        })
        .catch(() => {
          console.log("fail");
          this.setState({ loading: false });
        });
    });
  };
  handleSentButton = () => {
    this.setState({ sentClicked: true, loading: true });
    this.props
      .protectedFetch(config.backendServer + "notifyitemsent", { id: this.state.id }, errorFunctions.checkResponseSent)
      .then((res) => {
        this.retrieveWarranty(this.state.id);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
  };
  downloadStartPostageLabel = () => {
    const id = parseInt(this.state.id);
    // Can't download files on iOS. If iOS, set pop-up saying 'please use computer instead'
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      this.setState({ showIosPopup: true });
      return; // Exit function now
    }

    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "downloadfile", { id, type: "start_postage_label" }, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok) {
          const filename = res.headers.get("FileName");
          res.blob().then((res) => {
            const blob = res;
            blob.name = filename;
            utilityFunctions.downloadFileAutomatically(blob);
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading && !this.state.sentClicked) {
      // Modal is active when loading is active. If open together, don't show loading
      return <LoadingPage />;
    } else if (!this.state.details) {
      return (
        <div className={"page"}>
          <h2 className={"pageHeader"}>Error - page not found</h2>
        </div>
      );
    }

    const details = this.state.details || {};
    const status = details.status;
    const history = details.history;

    const statuses = ["Created", "Approved", "In transit", "Under review", "Under servicing", "Complete"];

    const currentStatusIndex = statuses.indexOf(status) + 1;

    const statusDetails = [
      {
        activeText: "Created",
        disabledText: "Created",
        icon: <CreatedIcon />,
        color: "#2475B0",
      },
      {
        activeText: "Awaiting Approval",
        disabledText: "Approved",
        activeSubText: (
          <span>
            <span style={{ display: "block", marginBottom: "12px" }}>The Roborock Service team are currently looking at your warranty request.</span>
            <span style={{ display: "block" }}>
              If you have any questions or concerns, contact us on <a href={"mailto:service@roborock.com.au"}>service@roborock.com.au</a>.
            </span>
          </span>
        ),
        icon: <ApprovedIcon />,
        color: "#00695C",
      },
      {
        activeText: "Send device",
        disabledText: "You sent your Roborock device",
        activeSubText: (
          <span>
            {details.has_start_postage ? (
              <span>
                <span>Please find your postage label here. After you have sent the device, please press the 'Mark as sent' button below.</span>
                <button className={"button"} onClick={this.downloadStartPostageLabel} style={{ marginTop: "5px" }}>
                  Download postage label
                </button>
                <button className={"button"} onClick={this.handleSentButton} style={{ marginTop: "5px" }}>
                  Mark as sent
                </button>
              </span>
            ) : (
              <span>
                <span>You will be receiving a postage label soon to send your device.</span>
              </span>
            )}
          </span>
        ),
        icon: <InTransitIcon />,
        color: "#1A237E",
      },
      {
        activeText: "Our service team is waiting for your device",
        disabledText: "We received your device",
        activeSubText: "You will be notified when we receive it.",
        icon: <ReceivedIcon />,
        color: "#7B1FA2",
      },
      {
        activeText: "Our service team is reviewing your device",
        disabledText: "We have reviewed your device",
        activeSubText: "You will be notified when the review process is over.",
        icon: <ReviewIcon />,
        color: "#B71C1C",
      },
      {
        activeText: "Device being serviced",
        disabledText: "Device has been fixed",
        activeSubText: "Device has been successfully reviewed and is currently being fixed.",
        icon: <ServiceIcon />,
        color: "#E65100",
      },
      {
        activeText: "Complete",
        disabledText: "Complete",
        activeSubText: (
          <span>
            <span style={{ display: "block" }}>Device has been successfully fixed and is coming back to you.</span>
            {details.end_tracking_number && (
              <span style={{ display: "block", marginTop: "12px" }}>Tracking number: {details.end_tracking_number}</span>
            )}
          </span>
        ),
        icon: <CompleteIcon />,
        color: "#212121",
      },
    ];

    const declinedStatusDetails = [
      {
        activeText: "Created",
        disabledText: "Created",
        icon: <CreatedIcon />,
        color: "#2475B0",
      },
      {
        activeText: "Declined",
        disabledText: "Declined",
        activeSubText: (
          <span>
            <span style={{ display: "block", marginBottom: "12px" }}>Your Roborock warranty claim was rejected.</span>
            <span style={{ display: "block", marginBottom: "12px" }}>Reason: {details.comments}</span>
            <span style={{ display: "block" }}>
              If you have any questions or concerns, contact us on <a href={"mailto:service@roborock.com.au"}>service@roborock.com.au</a>.
            </span>
          </span>
        ),
        icon: <DeclinedIcon />,
        color: "#D50000",
      },
    ];

    const statusDetailsToUse = status === "Declined" ? declinedStatusDetails : statusDetails;
    const statusIndexToUse = status === "Declined" ? 1 : currentStatusIndex;

    return (
      <HalfPageImageRight background={backgroundImg}>
        <h2 className={"pageHeader"}>
          Warranty Claim {this.state.id} for {details.name}
        </h2>
        {details.out_of_warranty && <div className={"warranty-out-of-warranty-flag"}>Out of Warranty</div>}
        <div className={"pageContent clearfix"}>
          <Stepper activeStep={statusIndexToUse} orientation="vertical">
            {statusDetailsToUse.map((obj, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={(props) => <StepIcon {...props} icon={obj.icon} color={obj.color} />}
                  className={"single-warranty-step-label"}
                >
                  {statusIndexToUse > index ? obj.disabledText : obj.activeText}{" "}
                  {history[statuses[index]] ? "- " + moment(history[statuses[index]]).fromNow() : ""}
                </StepLabel>
                {obj.activeSubText && (
                  <StepContent className={"single-warranty-step-content"}>
                    <Typography>{obj.activeSubText}</Typography>
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </div>
        <Modal
          isOpen={this.state.sentClicked}
          onRequestClose={() => this.setState({ sentClicked: false })}
          contentLabel={"Item has been sent."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ sentClicked: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Thanks for notifying us!</div>
          <div className={"info-sub-text"}>We will get back to you when we have received your item and have solved the issue.</div>
        </Modal>
      </HalfPageImageRight>
    );
  }
}

class StepIcon extends Component {
  render() {
    const isActive = this.props.active || this.props.completed;
    return (
      <div style={isActive ? { background: this.props.color } : {}} className={`single-warranty-stepper-icon-container`}>
        {this.props.icon}
      </div>
    );
  }
}
