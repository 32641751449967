const baseUrl = process.env.REACT_APP_BASE_URL.length > 0 ? process.env.REACT_APP_BASE_URL : "staging.warrantyservices.com.au";
const apiUrl = "https://" + baseUrl + "/api/";
const country = process.env.REACT_APP_COUNTRY;
const config = {
  backendServer: apiUrl,
  activateGoogleAnalytics: true,
  country: country,
};

export default {
  // Add common config values here
  googleAnalyticsId: "UA-151355493-1",
  ...config,
};
