import React, { Component } from "react";
import { MdClose } from "react-icons/md";
import config from "../../../config";

import errorFunctions from "../../../store/errorFunctions";

export default class ShippingLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      disableInput: true,
      name: "",
      address: "",
      suburb: "",
      suburbOptions: [],
      stateVal: "",
      postcode: "",
      phone: "",
      email: "",
      country: "AU",
      deviceName: "",
      width: "",
      length: "",
      height: "",
      weight: "",
      description: "",
      errorNotification: "",
      shippingLabel: "",
      status: "None",
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  downloadPdf = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(
        config.backendServer + "booking/auspost/label/?ticket_id=" + this.props.ticketId,
        "GET",
        null,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        if (res) this.setState({ loading: false, shippingLabel: res.label });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  retriveDefaultData = () => {
    this.setState({
      name: this.props.userDevice.name || "",
      address: this.props.userDevice.address_line_one + " " + this.props.userDevice.address_line_two || "",
      suburb: this.props.userDevice.suburb || "",
      stateVal: this.props.userDevice.state || "",
      postcode: this.props.userDevice.postcode || "",
      phone: this.props.userDevice.mobile || "",
      email: this.props.userDevice.email || "",
      country: "AU",
      deviceName: this.props.userDevice.device?.name || "",
      width: this.props.userDevice.device?.width || "",
      length: this.props.userDevice.device?.length || "",
      height: this.props.userDevice.device?.height || "",
      weight: this.props.userDevice.device?.weight || "",
      description: this.props.userDevice.device.description || "",
      disableInput: false,
      loading: false,
    });
  };
  searchLocationState = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "location/state/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.map((x) => {
          return { value: x.code, label: x.name };
        });
        this.setState({
          stateOptions: options,
          loading: false,
        });
      })
      .catch(() => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  searchLocationSuburb = (qState) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "location/suburb/?state=" + qState, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.results.map((x) => {
          return { value: x.name, label: x.name };
        });
        console.log(options);
        this.setState({
          suburbOptions: options,
          loading: false,
          // suburb: options[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  retrieveTransdirectInfo = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(
        config.backendServer + `booking/${this.props.userDevice.device.shipping_vendor}/${this.props.ticketId}`,
        "GET",
        null,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        this.setState({
          loading: false,
          width: res.item.width || this.props.userDevice.device?.width,
          length: res.item.length || this.props.userDevice.device?.length,
          height: res.item.height || this.props.userDevice.device?.height,
          weight: res.item.weight || this.props.userDevice.device?.weight,
          description: res.item.description || this.props.userDevice.device.description,
          name: res.sender.name || this.props.userDevice.name,
          address: res.sender.address || this.props.userDevice.address,
          suburb: res.sender.suburb || this.props.userDevice.suburb,
          stateVal: res.sender.state || this.props.userDevice.state,
          postcode: res.sender.postcode || this.props.userDevice.postcode,
          phone: res.sender.phone || this.props.userDevice.phone,
          email: res.sender.email || this.props.userDevice.email,
          country: res.sender.country || this.props.userDevice.country,
          shippingLabel: "",
          status: res.status,
        });
        console.log(res);
        this.downloadPdf();
      })
      .catch((err) => {
        console.log(err);
        this.retriveDefaultData();
      });
  };
  componentDidMount() {
    this.retrieveTransdirectInfo();
    this.searchLocationState();
  }

  render() {
    return (
      <>
        {/* {this.state.loading && <LoadingPage className="w-10" />} */}
        <form className="card" style={{ width: "auto" }}>
          <div className="alert alert-danger" role="alert" style={{ display: this.state.errorNotification ? "default" : "none" }}>
            {this.state.errorNotification}
          </div>
          <button onClick={() => this.props.handleCloseModal()} className={"confirm-modal-close"}>
            <MdClose />
          </button>

          <div className="card-header">
            <h5>{`Shipping Label ${this.props.userDevice.device.shipping_vendor === "auspost" ? "Australian Post" : "Transdirect"}`}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="card col-4 mr-2 ml-2">
                <div className="card-body">
                  <h6 className="card-title">{`Device : ${this.state.deviceName}`}</h6>
                  <div className={"row mb-3"}>
                    <div className="col">
                      <label className={"col-form-label col-form-label-sm"}>Description*</label>
                      <input
                        type="text"
                        id="description"
                        //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        required={true}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                        value={this.state.description}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={"row mb-3"}>
                    <div className="col">
                      <label className={"col-form-label col-form-label-sm"}>Width*</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="width"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.width}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className={"col-form-label col-form-label-sm"}>Length*</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="length"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.length}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className={"col-form-label col-form-label-sm"}>Height*</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="height"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.height}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">cm</span>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className={"col-form-label col-form-label-sm"}>Weight*</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          id="weight"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.weight}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">kg</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="alert alert-info mt-3 ">
                    <div>
                      <span>Status </span>
                      <span className="k-status k-status-pending ">{this.state.status}</span>
                    </div>
                    {this.state.shippingLabel.length > 0 && (
                      <div className="row gap-2 p-2">
                        <button className="k-btn k-btn-primary col">
                          <a
                            disabled={this.state.shippingLabel.length === 0}
                            href={this.state.shippingLabel}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-light"
                          >
                            Download
                          </a>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card col mr-3">
                <div className="card-body">
                  <h6 className={"card-title"}>Customer Details</h6>
                  <div className="row">
                    <div className={"col"}>
                      <label className="col-form-label col-form-label-sm">Owner* </label>
                      {/* Super admin links to user, normal user doesn't have link */}
                      <input
                        type="text"
                        id="name"
                        disabled={true}
                        //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        required={true}
                        className="form-control form-control-sm text-sm"
                        onChange={this.handleChange}
                        value={this.state.name}
                      />
                    </div>
                    <div className={"col"}>
                      <label className="col-form-label col-form-label-sm">Email* </label>
                      <input
                        type="text"
                        id="email"
                        disabled={true}
                        required={true}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                        value={this.state.email}
                      />
                    </div>
                  </div>

                  <div className={"single-warranty-details-item"}>
                    <label className="col-form-label col-form-label-sm">Address* </label>
                    <textarea
                      // type="text-area"
                      id="address"
                      disabled={true}
                      required={true}
                      className="form-control form-control-sm"
                      onChange={this.handleChange}
                      value={this.state.address}
                    />
                  </div>
                  <div className="row mt-3">
                    <div className={"col"}>
                      <label className="col-form-label col-form-label-sm">State* </label>

                      <input disabled className="form-control form-control-sm" value={this.state.stateVal} />
                    </div>
                    <div className={"col"}>
                      <label className="col-form-label col-form-label-sm">Suburb* </label>

                      <input disabled className="form-control form-control-sm" value={this.state.suburb} />
                    </div>

                    <div className={"col"}>
                      <label className="col-form-label col-form-label-sm">Postcode* </label>
                      <input disabled className="form-control form-control-sm" value={this.state.postcode} />
                    </div>

                    <div className="row mt-3">
                      <div className={"col"}>
                        <label className="col-form-label col-form-label-sm">Phone* </label>
                        <input
                          type="tel"
                          id="phone"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control form-control-sm"
                          onChange={this.handleChange}
                          value={this.state.phone}
                        />
                      </div>
                      <div className={"col"}>
                        <label className="col-form-label col-form-label-sm">Country* </label>
                        <input
                          type="text"
                          id="country"
                          disabled={true}
                          //  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required={true}
                          className="form-control form-control-sm"
                          onChange={this.handleChange}
                          value={"AU"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer">
            <div className="row">
              <div className="col-1">
                <button className="btn btn-outline-secondary " onClick={() => this.props.handleCloseModal()}>
                  Close
                </button>
              </div>
              {this.state.loading && (
                <div className="col-4">
                  <div className="spinner-border" role="status">
                    {/* <span className="sr-only">Loading...</span> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}
