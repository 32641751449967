import React, { Component } from "react";
import "./halfPageImageRight.css";

export default class HalfPageImageRight extends Component {
  resize = () => {
    this.forceUpdate();
  };
  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    let width = window.innerWidth;
    let backgroundImgStyle = {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundImage:
        "url(https://dreame.com.au/cdn/shop/files/20210615-D9_Max_-_-_-1.jpg?v=1685683599)",
    };

    return (
      <div className={"half-page-image-right-container clearfix"}>
        <div className={"half-page-image-right-content-side"}>
          <div className={"half-page-image-right-content-holder"}>
            {this.props.children}
          </div>
        </div>
        {width > 450 && (
          <div
            style={backgroundImgStyle}
            className={"half-page-image-right-img-side"}
          />
        )}
      </div>
    );
  }
}
