import React, { Component } from "react";
import "./AddNewWarranty.css";
import "../../common.css";
import config from "../../../config";
import { Redirect } from "react-router-dom";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";

export default class AddNewWarranty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proofOfPurchase: "yes",
      redirect: false,
      isLoading: true,
      reason: [],
      myDevices: [],
    };
  }
  componentDidMount() {
    this.retrieveDevices();
  }
  // Retrieve devices for Select box
  retrieveDevices = () => {
    this.setState({ isLoading: true });
    this.props
      .protectedFetch(config.backendServer + "getdevices", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ isLoading: false, myDevices: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  };
  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let values = this.state[name],
      valueExists = values.indexOf(value);

    if (checked) {
      // Add if doesn't exist
      if (valueExists === -1) {
        values.push(value);
      }
    } else {
      // Remove if does exist
      if (valueExists > -1) {
        values.splice(valueExists, 1);
      }
    }

    this.setState({ [name]: values });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      [id]: value,
    });
  };
  handleSubmit = (e) => {
    // this.setState({isLoading: true});
    e.preventDefault();
    let formData = new FormData();
    formData.append("reason", JSON.stringify(this.state.reason));
    formData.append("device", document.getElementById("device").value);
    if (this.state["systemError"]) {
      formData.append("error", this.state["systemError"]);
    }
    if (this.state["otherDetail"]) {
      formData.append("other", this.state["otherDetail"] || null);
    }

    this.props
      .protectedFetch(config.backendServer + "addnewwarranty", formData, (res) => res.ok, true)
      .then((res) => {
        console.log(res);
        // On resolved and rejected, need to take off loading screen
        this.setState({ redirect: true, isLoading: false });
      })
      .catch(() => {
        console.log("fail");
      });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    } else if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    const checkboxes = [
      "More noise than usual",
      "Bad smell/burning smell from the machine",
      "Can not connect and sync with mobile application",
      "Can not connect Bluetooth",
      "Can not charge the machine",
      "Slow charging/can not charge in full",
      "Spinning around in one place",
      "Wheels are not working",
      "Not going back to the charging dock (Note: Before deciding to proceed with warranty for this issue, please follow the troubleshooting instructions and make sure the machine has been reset/restarted properly. If our technicians can not find any fault with the machine and the issue is fixed by just a restart, you will be charged for the shipping fee.)",
    ];
    const devices = this.state.myDevices;

    return (
      <div className={"page add-new-warranty-page"}>
        <h2 className={"pageHeader"}>Submit Warranty Request</h2>
        <div className={"pageContent"}>
          <form onSubmit={this.handleSubmit}>
            <div className="form-field">
              <label>Device</label>
              <select id={"device"} className="form-select ">
                {devices.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name} - {item.serial_number}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={"add-new-warranty-special-input-header"}>Please select and provide reason of return below:</div>
            {checkboxes.map((item, i) => {
              return (
                <label
                  className={`add-new-warranty-checkbox-container ${item.length > 50 ? "add-new-warranty-checkbox-container-full-width" : ""}`}
                  key={i}
                >
                  <input type="checkbox" id={"reason" + i} name="reason" value={item} onChange={this.handleCheckboxChange} />
                  <span className={"add-new-warranty-checkbox-text"}>{item}</span>
                </label>
              );
            })}

            <div className="form-field">
              <label>Is there a System Error? (Error 1-18)</label>
              <input type={"text"} onChange={this.handleInputChange} id={"systemError"} />
            </div>
            <div className={"form-field"}>
              <label>Other issue, please describe in details</label>
              <textarea onChange={this.handleInputChange} id={"otherDetail"} />
            </div>
            <button className="form-submit" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}
