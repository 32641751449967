import React, { Component } from "react";
import "./Loading.css";
import loadingImage from "../../img/loading.gif";

export default class LoadingPage extends Component {
    render() {
        return (
            <div className={this.props.small ? "fitInBoxLoadingPage" : "fullScreenLoadingPage" /* if small is set, then don't make full page */}>
                <img alt={"loading"} src={loadingImage}/>
            </div>
        );
    }
}