import React, { Component } from "react";

import "../MyDevices.css";
import "../../common.css";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import Modal from "react-modal";
import LoadingPage from "../../../components/Loading/Loading";
import Tabs from "../../../components/Tabs/Tabs";
import TicketTable from "./TicketTable";
import { Link } from "react-router-dom";
import { icons } from "../../../img/icons";

import SetWarrantyDetails from "../../Warranties/SetWarrantyDetails/SetWarrantyDetails";
import moment from "moment";

Modal.setAppElement("body");

export default class DeviceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
    };
  }

  componentDidMount() {
    const ticketId = this.props.computedMatch.params.id;
    this.retrieveDeviceDetail(ticketId);
  }

  retrieveDeviceDetail = (id) => {
    this.props
      .protectedFetch(config.backendServer + "user_device/" + id + "/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        // console.log(res);
        this.setState({ loading: false, device: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  render() {
    const closedStatus = [
      "Did Not Proceed",
      "Not Approved",
      "Service Completed",
      "Completed",
      "Refund Processed",
      "Replacement Sent",
      "Repair complete, goods sent out",
    ];
    const ticketlength = this.state.device?.tickets.length || 0;
    const ticket_type = this.state.device?.tickets[ticketlength - 1]?.ticket_type.toUpperCase() || "";
    const lastTicket = this.state.device?.tickets[this.state.device?.tickets.length - 1];
    const lastTicketStatus = lastTicket?.status;
    const isAddReport = !lastTicketStatus || closedStatus.includes(lastTicketStatus);
    if (!this.state.device) return <LoadingPage />;
    return (
      <div className={"page"}>
        <Link to="/" className="link-dark text-decoration-none">
          <h6>{"< Back to my device"}</h6>
        </Link>

        <div className="device-card device-single-card">
          <div className="row no-margin">
            <div className="col-lg-2 col-md-12">
              <img
                src={this.state.device.device.image || "https://www.dreametech.com/cdn/shop/products/20220902103443_590x.png?v=1662089670"}
                width={"100%"}
                alt=""
              />
            </div>
            <div className="col">
              <div className="device-card-header"> {this.state.device.device.brand.name + " " + this.state.device.device.name}</div>
              <div className="row">
                <div className="col-lg col-sm-12 device-card-line">
                  <img src={icons.CreditCard} className="device-card-icon " alt="" />
                  <span> Series Number : {this.state.device.serial_number}</span>
                </div>
                <div className="col-lg col-sm-12 device-card-line">
                  <img src={icons.StoreFront} className="device-card-icon" alt="" />
                  <span> Seller Details : {this.state.device.seller_details}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg col-sm-12 device-card-line ">
                  <img src={icons.LocationOn} className="device-card-icon" alt="" />
                  <span> Store Location : {this.state.device.store_location}</span>
                </div>
                <div className="col-lg col-sm-12 device-card-line">
                  <img src={icons.DateRange} className="device-card-icon" alt="" />
                  <span> Purchase Date : {this.state.device.date_of_purchase}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-12 text-end">
              {isAddReport && (
                <button
                  className={"k-btn k-btn-primary"}
                  // onClick={() => that.setState({ addWarrantyModal: i })}
                  onClick={() => {
                    this.setState({
                      showApproveModal: true,
                      userDevice: this.state.device.id,
                    });
                  }}
                >
                  Log Fault
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="row justify-content-between">
            <h6>Warranty Information </h6>
            <div className="col-lg col-sm-12">
              <div>Device Registered : {moment(this.state.device.created).format("DD MMMM YYYY")}</div>
              <div>Warranty Status : {ticket_type === "OUT_OF_WARRANTY" ? " Out of Warranty" : " In Warranty"}</div>
            </div>
            {this.state.device.proof_of_purchase && (
              <div className="col-lg-3 col-sm-12 text-end">
                <a
                  href={this.state.device.proof_of_purchase_photo}
                  target="_blank"
                  rel="noopener noreferrer"
                  // className="link-dark text-decoration-none"
                  // style={{ textDecoration: "none", color: "gray" }}
                >
                  <button className="k-btn k-btn-primary">Proof of Purchase</button>
                </a>
              </div>
            )}
          </div>
        </div>
        <Tabs defaultTab={this.state.defaultTab} resetTab={() => this.setState({ defaultTab: undefined })}>
          <div label="All Tickets">
            <div>
              {this.state.device.tickets.length > 0 ? (
                <TicketTable
                  tickets={this.state.device.tickets}
                  protectedFetch={this.props.protectedFetch}
                  retrieveDeviceDetail={() => this.retrieveDeviceDetail(this.state.device.id)}
                />
              ) : (
                <div className="device-add-report text-center">
                  <img src={icons.Paper} className="device-add-report-img" alt="" />
                  <h5>No ticket submitted on this device.</h5>
                  <div>For device problems, you can submit a report and, if applicable, claim your warranty.</div>
                  <div className="device-add-report-btn ">
                    <button
                      className={"k-btn k-btn-primary"}
                      // onClick={() => that.setState({ addWarrantyModal: i })}
                      onClick={() => {
                        this.setState({
                          showApproveModal: true,
                          userDevice: this.state.device.id,
                        });
                      }}
                    >
                      Log Fault
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div label="Open Tickets">
            <TicketTable
              tickets={this.state.device.tickets.filter((x) => !closedStatus.includes(x.status))}
              protectedFetch={this.props.protectedFetch}
              retrieveDeviceDetail={() => this.retrieveDeviceDetail(this.state.device.id)}
            />
          </div>
          <div label="Done">
            <TicketTable
              tickets={this.state.device.tickets.filter((x) => closedStatus.includes(x.status))}
              protectedFetch={this.props.protectedFetch}
              retrieveDeviceDetail={() => this.retrieveDeviceDetail(this.state.device.id)}
            />
          </div>
        </Tabs>
        <SetWarrantyDetails
          userDevice={this.state.device.id}
          protectedFetch={this.props.protectedFetch}
          showModal={this.state.showApproveModal}
          hideModal={() => this.setState({ showApproveModal: false })}
          retrieveDevices={() => this.retrieveDeviceDetail(this.state.device.id)}
          // retrieveDevices={this.retrieveDeviceDetail(this.state.device.id)}
        />
      </div>
    );
  }
}
