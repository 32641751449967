/*
Functions that are common to multiple components (and even multiple projects)
 */

let utilityFunctions = {
  generateUUID: function () {
    let d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  },
  returnNestedOrFail: function (obj /*, level1, level2, ... levelN*/) {
    //If looking for a value in a sub-object, you need to check the validity of all the parent objects first. This function allows you to skip the validity check
    let args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return null;
      }
      obj = obj[args[i]];
    }
    return obj;
  },
  setCookie: function (cname, cvalue, exdays) {
    var expires = "";
    if (exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      expires = "expires=" + d.toUTCString();
    }
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie: function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  validateEmail: function (email) {
    //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    //Haven't verified this works yet since I haven't used this function yet - not implemented in this project yet
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  dataURLtoFile: function (dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  downloadFileAutomatically: function (file, fileType) {
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(file, file.name);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download =
        fileType === "application/pdf"
          ? `${file.name}.pdf`
          : `${file.name}.jpg`;
      a.click();
      window.URL.revokeObjectURL(url);
      setTimeout(function () {
        document.body.removeChild(a);
      }, 1000);
    }
  },
  timeDifferenceBetweenTwoTimes: function (timeA, timeB) {
    //create date format - note date is dummy and is irrelevant
    const timeStart = new Date("01/01/2007 " + timeA);
    const timeEnd = new Date("01/01/2007 " + timeB);

    let hourDifference = (timeEnd - timeStart) / 60 / 60 / 1000;

    return hourDifference;
  },
  //Below gets 1st, 2nd, 3rd etc from a number
  //Source: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
  ordinal_suffix_of: function (i) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  },
  loadScript: function (url, callback) {
    // https://stackoverflow.com/questions/950087/how-do-i-include-a-javascript-file-in-another-javascript-file
    // Adding the script tag to the head as suggested before
    const head = document.head;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;

    // Fire the loading
    head.appendChild(script);
  },
};

export default utilityFunctions;
