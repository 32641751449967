import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "./pages/AuthenticationPages/Login";
import "./App.css";
import AuthContainer from "./pages/AuthenticationPages/AuthContainer";
import NotFound from "./pages/NotFound/NotFound";
import Signup from "./pages/AuthenticationPages/Signup";
import ForgotPassword from "./pages/AuthenticationPages/ForgotPassword";
import ResetPassword from "./pages/AuthenticationPages/ResetPassword";
import CreatePassword from "./pages/AuthenticationPages/CreatePassword";
import VerifySignup from "./pages/AuthenticationPages/VerifySignup";
import SignupVerified from "./pages/AuthenticationPages/SignupVerified";
import ForgotPasswordConfirm from "./pages/AuthenticationPages/ForgotPasswordConfirm";
import ResetPasswordConfirm from "./pages/AuthenticationPages/ResetPasswordConfirm";
import CreatePasswordConfirm from "./pages/AuthenticationPages/CreatePasswordConfirm";
import utilityFunctions from "./store/utilityFunctions";
import errorFunctions from "./store/errorFunctions";
import LoadingPage from "./components/Loading/Loading";
import config from "./config";
import NeedVerification from "./pages/AuthenticationPages/NeedVerification";
import MyDevices from "./pages/MyDevices/MyDevices";
import DeviceDetail from "./pages/MyDevices/DeviceDetail/DeviceDetail";
import AddNewDevice from "./pages/MyDevices/AddNewDevice/AddNewDevice";
import PageWithNavbar from "./pageContainers/PageWithNavbar/PageWithNavbar";
import AddNewWarranty from "./pages/Warranties/AddNewWarranty/AddNewWarranty";
import SingleWarranty from "./pages/Warranties/SingleWarranty/SingleWarranty";
import UserProfile from "./pages/UserProfile/UserProfile";

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isVerified: false, // Set to true if account is verified (in authentication check)
    };

    //Check if token exists, and if does set it
    const login = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    const userInfo = login && JSON.parse(login);
    if (userInfo) {
      this.state.userInfo = userInfo;
    }
  }
  componentDidMount() {
    this.validateUser();
    document.title = config.country === "AU" ? "Dreame Australia" : "Dreame New Zeland";
    // this.validateUserDummy();

    // If Google Analytics is on, load it in
    if (config.activateGoogleAnalytics) {
      utilityFunctions.loadScript(`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsId}`, () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag("js", new Date());

        window.gtag("config", "UA-151355493-1");
      });
    }
  }
  validateUser(callback) {
    // Check userInfo and whether it is valid or not
    if (this.state.userInfo) {
      // Need to validate token with server
      const payload = {
        token: this.state.userInfo.access_token,
      };

      this.protectedFetch(config.backendServer + "token/verify/", "POST", payload, errorFunctions.checkResponseSent)
        .then((res) => {
          // On resolved and rejected, need to take off loading screen
          this.setState(
            {
              isAuthenticated: true,
              isLoading: false,
              isVerified: true,
              isSuper: !!res.super /* force boolean */,
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        })
        .catch(() => {
          this.setState({ isLoading: false }, () => {
            if (callback) {
              callback();
            }
          });
          utilityFunctions.setCookie("userInfo", "", -20); //Setting expires to past should delete cookie
          localStorage.removeItem("userInfo");
        });
      // this.getBrands()
    } else {
      // If not logged in, routes will handle rest
      this.setState({ isLoading: false }, () => {
        if (callback) {
          callback();
        }
      });
      utilityFunctions.setCookie("userInfo", "", -20); //Setting expires to past should delete cookie
      localStorage.removeItem("userInfo");
    }
  }

  validateUserDummy(callback) {
    this.setState({ isLoading: false, isVerified: true }, () => {
      if (callback) {
        callback();
      }
    });
  }
  userHasAuthenticated = (authenticated, rememberMe, callback) => {
    this.setState({ isAuthenticated: authenticated });
    if (authenticated) {
      this.setState({ userInfo: JSON.parse(authenticated) });
      if (rememberMe) {
        localStorage.setItem("lgn", authenticated);
      } else {
        utilityFunctions.setCookie("lgn", authenticated);
      }
      if (callback) {
        callback();
      }
    }

    // this.validateUser(() => {
    //   if (callback) {
    //     callback();
    //   }
    // });
  };
  handleLogout = (event) => {
    this.userHasAuthenticated(false);
    this.setState({ isAuthenticated: null });
    utilityFunctions.setCookie("lgn", "", -20); //Setting expires to past should delete cookie
    localStorage.removeItem("lgn");
  };

  protectedFetch = (url, method, data, checkFunction, formData) => {
    if (!this.state.userInfo) return;
    const token = this.state.userInfo.access_token;
    const logout = this.handleLogout;
    return new Promise(function (resolve, reject) {
      let options;
      if (formData) {
        // True / false
        options = {
          method: method,
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        if (method !== "GET") options.body = data;
      } else {
        options = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        if (method !== "GET") options.body = JSON.stringify(data || {});
      }
      fetch(url, options)
        .then((response) => {
          // Check if token failure occurred - if so then trigger logout. Else use checkFunction
          if (response.status === 403) {
            reject("token invalid");
            logout();
            return null;
          } else if (response.status === 401) {
            // User is not verified, take to 'Account needs to be verified page'
            logout();
            reject("unauthorized");
            return null;
          } else if (response.status === 413) {
            // User is not verified, take to 'Account needs to be verified page'

            reject("not verified");
            return null;
          } else if (response.status === 400) {
            reject("User device is already registered");
            return null;
          } else if (response.status === 200) {
            return response.json();
          } else {
            return checkFunction(response);
          }
        })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject("check function failed");
          }
        });
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage />;
    }
    return (
      <Router>
        <div style={{ width: "100%", height: "100%" }}>
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <MyDevices protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/device-detail/:id"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <DeviceDetail protectedFetch={this.protectedFetch} {...props} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/addnewdevice"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <AddNewDevice protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/editdevice/:id"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <AddNewDevice protectedFetch={this.protectedFetch} {...props} />
                </PageWithNavbar>
              )}
            />
            {/*<ProtectedRoute exact path="/warranty" test={this.state.isAuthenticated && this.state.isVerified}
                                        goToOnFailure={"/needsverification"}
                                        render={(props) =>
                                            <PageWithNavbar
                                                logout={this.handleLogout}
                                            >
                                                <Warranties protectedFetch={this.protectedFetch}/>
                                            </PageWithNavbar>}/>*/}
            <ProtectedRoute
              exact
              path="/addnewwarranty"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <AddNewWarranty protectedFetch={this.protectedFetch} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/warranty/:id"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <SingleWarranty protectedFetch={this.protectedFetch} {...props} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/profile"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <PageWithNavbar logout={this.handleLogout}>
                  <UserProfile protectedFetch={this.protectedFetch} {...props} />
                </PageWithNavbar>
              )}
            />
            <ProtectedRoute
              exact
              path="/needsverification"
              test={this.state.isAuthenticated}
              goToOnFailure={"/login"}
              render={(props) => (
                <AuthContainer>
                  <NeedVerification protectedFetch={this.protectedFetch} {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/login"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <Login onLogin={this.userHasAuthenticated} {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/signup"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <Signup {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/signupsuccess"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <VerifySignup {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/verify/:token"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <SignupVerified
                    handleToken={(val) => this.setState({ passwordToken: val })}
                    handleUid={(val) => this.setState({ uid: val })}
                    {...props}
                  />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/forgotpassword"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <ForgotPassword {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/forgotpasswordsuccess"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <ForgotPasswordConfirm {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/resetpassword/:token"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <ResetPassword {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/createpassword/:token"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <CreatePassword uid={this.state.uid} passwordToken={this.state.passwordToken} {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/resetpasswordsuccess"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <ResetPasswordConfirm {...props} />
                </AuthContainer>
              )}
            />
            <ProtectedRoute
              exact
              path="/createpasswordsuccess"
              test={true}
              goToOnFailure={"/notfound"}
              render={(props) => (
                <AuthContainer>
                  <CreatePasswordConfirm {...props} />
                </AuthContainer>
              )}
            />
            <Route path="/about/" component={About} />
            <Route path="/users/" component={Users} />
            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound} />
          </Switch>
          {/* Below route renders on every page - just want to use it to send to Google Analytics etc */}
          <Route
            path="/"
            render={({ location }) => {
              if (config.activateGoogleAnalytics && typeof window.gtag === "function") {
                window.gtag("config", config.googleAnalyticsId, {
                  page_location: window.location.href,
                  page_path: location.pathname,
                });
              }
              return null;
            }}
          />
        </div>
      </Router>
    );
  }
}

//Show if route shouldn't be available
class ProtectedRoute extends React.Component {
  render() {
    const { component: Component, ...props } = this.props;
    return <Route {...props} render={(props) => (!!this.props.test ? this.props.render(this.props) : <Redirect to={this.props.goToOnFailure} />)} />;
  }
}
