import React, { Component } from "react";
import "./Navbar.css";
import logo from "../../img/logo.png";
import { MdExpandMore } from "react-icons/md";
import { Link, NavLink, withRouter } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      width: window.innerWidth,
      mobileMenuOpen: false,
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleSettingsLinkClick = this.handleSettingsLinkClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Close mobile or popup menu if link clicked
    if (!this.props.location || !prevProps.location || prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        showMenu: false, // Close desktop popup menu
        mobileMenuOpen: false, // Close mobile menu
      });
    }
  }

  resize = () => {
    this.setState({ width: window.innerWidth });
  };

  openMenu() {
    if (!this.state.showMenu) {
      this.setState({ showMenu: true });
    }
  }
  closeMenu() {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  }
  handleSettingsLinkClick(linkToGoTo) {
    this.props.history.push(linkToGoTo);
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleMobileMenuStateChange(state) {
    this.setState({ mobileMenuOpen: state.isOpen });
  }

  render() {
    const navbarLinks = {
      Devices: "/",
      //'Warranties': '/warranty'
    };
    const additionalSettings = { "My Profile": "/profile" };
    const handleSettingsLinkClick = this.handleSettingsLinkClick;

    if (this.state.width > 850) {
      //Desktop menu
      return (
        <div
          className={"NavbarAboveContainer" /* To set max-width on navbarContainer while keeping a white background */}
          tabIndex="0"
          onClick={this.closeMenu}
          onBlur={this.closeMenu /*Tab Index and blur used so that clicks outside of div will cause menu to close*/}
        >
          <div className="NavbarContainer">
            <Link to={"/"}>
              <img className={"NavbarLogo"} src={logo} alt={"logo"} />
            </Link>
            <div className={"NavbarLinkArea"}>
              {Object.keys(navbarLinks).map(function (linkName, i) {
                return (
                  <NavLink key={"navbar" + linkName} to={navbarLinks[linkName]} className={"NavbarLink"} activeClassName="NavbarLinkActive" exact>
                    {linkName}
                  </NavLink>
                );
              })}
            </div>
            <div className={"NavbarDropdown"} onClick={this.openMenu}>
              {this.props.type === "hospital" ? "Hospital" : "My"} Account <MdExpandMore className={"NavbarDropdownIcon"} />
            </div>
            {this.state.showMenu && (
              <div onClick={(e) => e.stopPropagation() /*Stop clicks in menu causing clicks above menu*/} className={"NavbarMenuContainer"}>
                {/* Options for settings comes from props*/}
                {Object.keys(additionalSettings).map(function (linkName, i) {
                  return (
                    <div
                      onClick={() => handleSettingsLinkClick(additionalSettings[linkName])}
                      //Mouse down is needed for IE11 bug - onBlur triggers before click - so using mousedown
                      onMouseDown={() => handleSettingsLinkClick(additionalSettings[linkName])}
                      className={"NavbarMenuItem"}
                      key={"settings" + linkName}
                    >
                      {linkName}
                    </div>
                  );
                })}
                <div
                  onClick={this.props.logout}
                  //Mouse down is needed for IE11 bug - onBlur triggers before click - so using mousedown
                  onMouseDown={this.props.logout}
                  className={"NavbarMenuItem"}
                >
                  Sign Out
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"NavbarAboveContainer" /* To set max-width on navbarContainer while keeping a white background */}>
          <Menu isOpen={this.state.mobileMenuOpen} onStateChange={(state) => this.handleMobileMenuStateChange(state)}>
            <div className={"NavbarMobileMenuHeader"}>
              <Link to={"/"}>
                <img alt="logo" className={"NavbarLogoInHeader"} src={logo} />
              </Link>
            </div>
            {Object.keys(navbarLinks).map(function (linkName, i) {
              return (
                <NavLink key={"navbar" + linkName} to={navbarLinks[linkName]} className={"NavbarLink"} activeClassName="NavbarLinkActive" exact>
                  {linkName}
                </NavLink>
              );
            })}
            {Object.keys(additionalSettings).map(function (linkName, i) {
              return (
                <NavLink
                  key={"navbar" + linkName}
                  to={additionalSettings[linkName]}
                  className={"NavbarLink"}
                  activeClassName="NavbarLinkActive"
                  exact
                >
                  {linkName}
                </NavLink>
              );
            })}
            <div onClick={this.props.logout} className={"NavbarLink"}>
              Sign Out
            </div>
          </Menu>
          <div className="NavbarContainer">
            <Link to={"/"}>
              <img alt="logo" className={"NavbarLogo"} src={logo} />
            </Link>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(Navbar); //Adding router to this so I can access 'history' prop, which is used to redirect profile, which is done because setting a link in the dropdown appears to never trigger some reason
