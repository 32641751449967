import React, { Component } from "react";
import "./AddNewDevice.css";
import "../../common.css";
import config from "../../../config";
import { Link, Redirect } from "react-router-dom";
import HalfPageImageRight from "../../../pageContainers/halfPageImageRight/halfPageImageRight";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";
import axios from "axios";
import utilityFunctions from "../../../store/utilityFunctions";
import { icons } from "../../../img/icons";
export default class AddNewDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proofOfPurchase: "yes",
      redirect: false,
      isLoading: false,
      id: null,
      deviceName: "",
      deviceId: null,
      devices: [],
      dateOfPurchase: "",
      serialNumber: "",
      textProofOfPurchase: "",
      fileProofOfPurchaseName: "",
      device: "",
      allowUpload: true,
      sellerDetails: [],
      sellerDetailInput: "",
      sellerDetail: "",
      storeLocations: [],
      storeLocation: "-",
    };
  }
  componentDidMount() {
    this.retriveDevices();
    if (this.props.computedMatch) {
      this.setState({
        id: this.props.computedMatch.params.id,
        isLoading: true,
      });
    }
    this.retrieveSeller();
  }
  retrieveSeller() {
    this.props.protectedFetch(config.backendServer + "sellers/", "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      this.setState({
        isLoading: false,
        sellerDetails: res,
      });
      // this.retrieveStore(res[0].name);
    });
  }

  retrieveStore(seller) {
    const url = config.backendServer + "sellers/" + seller + "/store_locations/?country=" + config.country;
    this.props.protectedFetch(encodeURI(url), "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      this.setState({
        isLoading: false,
        storeLocations: res,
        storeLocation: "-",
      });
    });
  }

  retriveDevices() {
    this.props.protectedFetch(config.backendServer + "devices/", "GET", null, errorFunctions.checkResponseThenJson).then((res) => {
      const brandDevice = res.map((dev) => {
        return {
          id: dev.id,
          name: dev.brand.name + " " + dev.name,
        };
      });
      this.setState({
        isLoading: false,
        devices: brandDevice,
        deviceId: brandDevice[0].id,
      });
    });
  }
  retrieveDetails(id) {
    // Rather than make a new endpoint, just use devices endpoint and filter here
    this.props
      .protectedFetch(config.backendServer + "getdevices", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const filteredDevices = res.filter((item) => {
          return item.id === id;
        });
        this.setState({
          isLoading: false,
          deviceName: filteredDevices[0].name,
          dateOfPurchase: filteredDevices[0].date,
          sellerDetails: filteredDevices[0].seller_details,
          storeLocation: filteredDevices[0].store_location,
          serialNumber: filteredDevices[0].serial_number,
          proofOfPurchase: filteredDevices[0].proof_of_purchase ? "yes" : "no",
          textProofOfPurchase: filteredDevices[0].proof_of_purchase_text,
        });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  }
  handleRadioChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };
  handleSellerChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
    this.retrieveStore(value);
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    this.setState({ fileProofOfPurchaseName: file.name });
    if (!allowedTypes.includes(file.type)) {
      alert("Only JPG, JPEG, PNG, and PDF files are allowed.");
      this.setState({ allowUpload: false });
    } else {
      this.setState({ allowUpload: true });
    }
  };
  handleSubmit = (e) => {
    // this.setState({ isLoading: true });
    e.preventDefault();
    const userInfo = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    const token = JSON.parse(userInfo).access_token;

    let options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    let formData = new FormData();
    formData.append("device", this.state.device);
    formData.append("date_of_purchase", this.state.dateOfPurchase);
    formData.append("seller_details", this.state.sellerDetail === "Other" ? this.state.sellerDetailInput : this.state.sellerDetail);
    formData.append("store_location", this.state.storeLocation);
    formData.append("serial_number", this.state.serialNumber);
    formData.append("proof_of_purchase", this.state.proofOfPurchase);
    if (this.state.proofOfPurchase === "yes") {
      if (this.state.allowUpload === true) {
        formData.append("proof_of_purchase_photo", document.getElementById("uploadProofOfPurchase").files[0]);
      } else {
        alert("Only JPG, JPEG, PNG, WEBM, MOV and MP4  files are allowed.");
      }
    } else {
      formData.append("proof_text", this.state.textProofOfPurchase);
    }
    if (this.state.id) {
      formData.append("id", this.state.id);
      // If id in state, then existing device
      this.props
        .protectedFetch(config.backendServer + "editdevice", formData, (res) => res.ok, true)
        .then((res) => {
          console.log(res);
          // On resolved and rejected, need to take off loading screen
          this.setState({ redirect: true, isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          alert(err);
        });
    } else {
      // If no id in state, then new device
      axios
        .post(config.backendServer + "user_device/new/", formData, options)
        .then((response) => {
          // Update state or perform other actions
          this.setState({ redirect: true, isLoading: false });
        })
        .catch((error) => {
          // Handle the error or show an error message
          alert(error?.response?.data?.non_field_errors?.[0] || error?.response?.data?.serial_number?.[0] || "Something went wrong.. try later");
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage small={true} />;
    } else if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    return (
      <HalfPageImageRight>
        <h2 className={"pageHeader"}>{this.state.id ? "Edit Device" : "Add New Device"}</h2>
        <div className={"pageContent"}>
          <form onSubmit={this.handleSubmit}>
            <div key={0} className="form-input">
              <label>{"Device Name*"}</label>
              <select required={true} id="device" onChange={this.handleInputChange} value={this.state["device"]} className="form-select form-select">
                <option value="">{"---Select Device---"}</option>
                {this.state.devices &&
                  this.state.devices
                    // .filter((obj)=> obj.name.includes("DREAME"))
                    .map(function (device, i) {
                      return <option value={device.id}>{device.name}</option>;
                    })}
              </select>
            </div>
            <div key={1} className="form-input">
              <label>{"Date of Purchase*"}</label>
              <input
                type={"date"}
                required={true}
                onChange={this.handleInputChange}
                id={"dateOfPurchase"}
                class="form-control datepicker-css"
                value={this.state.dateOfPurchase}
              />
              <div className={"add-new-device-input-info"}>{"Date of Purchase"}</div>
            </div>
            <div key={2} className="form-input">
              <label>{"Seller details*"}</label>
              <select
                id={"sellerDetail"}
                required
                onChange={this.handleSellerChange}
                placeholder="---Select Seller---"
                // onChange={(val)=>{this.setState({sellerDetail:val}); this.retrieveStore(val)}}
                value={this.state.sellerDetail.name}
                className="form-select form-select"
              >
                <option key={-1}>{"---Select Seller---"}</option>
                {this.state.sellerDetails.map((val, j) => {
                  return <option key={j}>{val.name}</option>;
                })}
              </select>
              <div className={"add-new-device-input-info"}>{"Where did you purchase this from? (include seller details)"}</div>
            </div>
            <div key={7} className="form-input" style={{ display: this.state.storeLocations?.length > 0 ? "block" : "none" }}>
              <label>{"Store Location"}</label>
              <select
                id={"storeLocation"}
                onChange={this.handleInputChange}
                // onChange={(val)=> this.setState({storeLocation:val})}
                value={this.state.storeLocation}
                className="form-select form-select"
              >
                <option key={-1}>{"---Select Store Location---"}</option>
                {this.state.storeLocations.map((val, j) => {
                  return <option key={j}>{val.name}</option>;
                })}
              </select>
              <div className={"add-new-device-input-info"}>{"Store location where device is bought"}</div>
            </div>
            <div key={3} className="form-input" style={{ display: this.state.sellerDetail === "Other" ? "block" : "none" }}>
              <label>{"Input Seller*"}</label>
              <input
                className="form-control"
                type={"text"}
                required={this.state.sellerDetail === "Other"}
                onChange={this.handleInputChange}
                id={"sellerDetailInput"}
                value={this.state.sellerDetailInput}
              />
              <div className={"add-new-device-input-info"}>{"Input manually seller where device is bought"}</div>
            </div>
            <div key={4} className="form-input">
              <label>{"Serial Number*"}</label>
              <input
                className="form-control"
                type={"text"}
                required={true}
                onChange={this.handleInputChange}
                id={"serialNumber"}
                value={this.state.serialNumber}
              />
              <div className={"add-new-device-input-info"}>
                {
                  "To locate the Serial Number of your Dreame product, simply check the sticker on the device itself, typically found under the barcode. The Serial Number is often labelled as 'S/N' and can be identified easily."
                }
              </div>
            </div>

            <div key={5} className={"form-field add-new-device-radio-field"}>
              <label className="required">
                Proof of Purchase
                <span> :</span>
              </label>
              <input
                type="radio"
                name={"proofOfPurchase"}
                value="yes"
                onChange={this.handleRadioChange}
                required
                defaultChecked={this.state.proofOfPurchase === "yes"}
              />{" "}
              Yes
              <input
                type="radio"
                name={"proofOfPurchase"}
                value="no"
                onChange={this.handleRadioChange}
                required
                defaultChecked={this.state.proofOfPurchase === "no"}
              />{" "}
              No
            </div>
            {/* I'm a little lazy, hardcoding these custom variables in */}
            {this.state.proofOfPurchase === "yes" && (
              <div className={"form-field add-new-device-proof-field"}>
                <div>Please upload your proof of purchase</div>
                <label for="uploadProofOfPurchase" className="custom-file-upload">
                  <img src={icons.Upload} alt="" /> Choose File
                </label>
                <span>{`  ${this.state.fileProofOfPurchaseName ? this.state.fileProofOfPurchaseName : "No file choosen"}`}</span>
                <input
                  accept="image/jpeg, image/png, application/pdf"
                  type={"file"}
                  id={"uploadProofOfPurchase"}
                  // className="custom-file-upload"
                  required={true}
                  onChange={this.handleFileUpload}
                />
              </div>
            )}

            {this.state.proofOfPurchase === "no" && (
              <div className={"form-field add-new-device-proof-field"}>
                <label>Please provide details to prove where the purchase was from</label>
                <textarea required={true} onChange={this.handleInputChange} id={"textProofOfPurchase"} value={this.state.textProofOfPurchase} />
              </div>
            )}
            <div className={"add-new-device-proof-info form-input"}>
              All warranty claims must have proof of purchase in order for us to verify the warranty. We will not accept submissions with no proof.
            </div>
            <button className="k-btn k-btn-primary-full" type="submit">
              Submit
            </button>
            <Link to={"/"}>
              <button className={"k-btn k-btn-secondary-full "}>Cancel</button>
            </Link>
          </form>
        </div>
      </HalfPageImageRight>
    );
  }
}
