import React, { Component } from "react";
import "./Authentication.css";
import { Link } from "react-router-dom";

export default class ForgotPasswordConfirm extends Component {
  render() {
    return (
      <div>
        <h2 className={"auth-page-header"}>Forgot Password</h2>
        <div className={"auth-page-info"}>
          <p>An email has been sent. Please follow the instructions in your email received.</p>
          <Link to={"/login"}>
            <button className="k-btn k-btn-primary-full">Back to login</button>
          </Link>
        </div>
      </div>
    );
  }
}
