import React, { Component } from "react";
import "./Authentication.css";
import { Link } from "react-router-dom";

export default class CreatePasswordConfirm extends Component {
  render() {
    return (
      <div>
        <h2 className={"auth-page-header"}>Password successfully created</h2>
        <div className={"auth-page-info"}>
          Your password has been created. Login now by clicking the button
          below.
        </div>
        <Link className="auth-form-submit" to={"/login"}>
          Login
        </Link>
      </div>
    );
  }
}
